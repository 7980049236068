import Board from '../models/Board';
import BoardMember from '../models/BoardMember';
import Payment from '../models/Payment';
import ExpenseMember from '../models/ExpenseMember';
import Debt from '../models/Debt';
import Price from '../models/Price';

export function castModel(className, data) {
    const instance = new className();
    Object.assign(instance, data);
    return instance;
}

export function castBoard(data) {
    return castModel(Board, {
        ...data,
        members: data.members.map(m => castModel(BoardMember, m)),
    })
}

export function castPayment(data) {
    return castModel(Payment, {
        ...data,
        amount: new Price(data.amount),
        payer: castModel(BoardMember, data.payer),
        members: data.type === 'expense'
            ? data.members.map(m => castExpenseMember(m))
            : null,
        payee: data.type === 'transfer'
            ? castModel(BoardMember, data.payee)
            : null,
    })
}

export function castPaymentsArray(data) {
    return [...data.map(p => castPayment(p))];
}

export function castExpenseMember(data) {
    return castModel(ExpenseMember, {
        ...data,
        boardMember: castModel(BoardMember, data.boardMember),
        amount: new Price(data.amount),
    });
}

export function castDebt(data) {
    return castModel(Debt, {
        ...data,
        debtor: castModel(BoardMember, data.debtor),
        creditor: castModel(BoardMember, data.creditor),
        amount: new Price(data.amount),
    });
}

export function castDebtsArray(data) {
    return [...data.map(d => castDebt(d))];
}