import axios from 'axios';
import {castBoard, castDebtsArray, castPayment, castPaymentsArray} from '../util/castModel';
import Board from '../models/Board';

export default class Api {
    #axios;
    #baseUrl;
    #accessToken = null;
    #refreshToken = null;

    constructor(baseUrl) {
        if (baseUrl.endsWith('/')) {
            baseUrl = baseUrl.substring(0, baseUrl.length - 1);
        }
        this.#baseUrl = baseUrl;
        this.#axios = axios.create({
            baseURL: baseUrl,
        })
        this.#axios.interceptors.response.use(response => response, error => {
            if (error?.response?.status !== 401) {
                throw error
            }

            const pathname = new URL(error.request.responseURL).pathname

            const excludedPaths = [
                '/api/v2/profile',
                '/api/v2/auth/credentials',
                '/api/v2/auth/sso',
                '/api/v2/auth/refresh',
                '/api/v2/invite/',
            ]

            let isExcluded = false
            excludedPaths.forEach(ep => {
                if (pathname.startsWith(ep)) {
                    isExcluded = true
                }
            })

            if (!isExcluded) {
                window.location.reload()
                return
            }

            throw error
        })
    }

    setTokens(accessToken, refreshToken) {
        this.#accessToken = accessToken;
        this.#refreshToken = refreshToken;

        this.#axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    }

    unsetTokens() {
        this.#accessToken = null;
        this.#refreshToken = null;

        delete this.#axios.defaults.headers.common['Authorization']
    }

    hasTokens() {
        return this.#accessToken !== null && this.#refreshToken !== null;
    }

    // API Methods

    async authenticateByCredentials(credentials) {
        const {data} = await this.#axios.post('/api/v2/auth/credentials', credentials);

        return data;
    }

    async authenticateBySso(provider: string, token: string) {
        const {data} = await this.#axios.post('api/v2/auth/sso/' + provider, {
            accessToken: token,
        });

        return data;
    }

    async authenticateByRefresh() {
        const {data} = await this.#axios.post('/api/v2/auth/refresh', {
            refreshToken: this.#refreshToken,
        });

        return data;
    }

    async logout() {
        await this.#axios.post('/api/v2/auth/logout');
    }

    async getProfile() {
        const {data} = await this.#axios.get('/api/v2/profile');

        return data.data;
    }

    async updateProfile(input) {
        const {data} = await this.#axios.post('/api/v2/profile', input, {
            params: {
                '_method': 'PATCH',
            },
        })

        return data.data
    }

    async deleteProfile() {
        await this.#axios.delete('/api/v2/profile');
    }

    async getNotificationSettings() {
        const {data} = await this.#axios.get('/api/v2/notifications/settings');
        return data
    }

    async updateNotificationSettings(input) {
        const {data} = await this.#axios.post('/api/v2/notifications/settings', input, {
            params: {
                '_method': 'PUT',
            },
        });
        return data
    }

    async getBoardsList(page, filters) {
        const {data} = await this.#axios.get('/api/v2/boards', {
            params: {
                page,
                limit: 12,
                filter: filters,
            },
        });

        return {
            boards: data.data,
            meta: data.meta,
        }
    }

    async createBoard(input): Promise<Board> {
        const {data} = await this.#axios.post('/api/v2/boards', input);

        return castBoard(data.data);
    }

    async getBoard(boardId) {
        const {data} = await this.#axios.get('/api/v2/boards/' + boardId);

        return {
            board: castBoard(data.data),
            permissions: data.meta.permissions,
        }
    }

    async updateBoard(boardId, input) {
        const {data} = await this.#axios.post('/api/v2/boards/' + boardId, input, {
            params: {
                '_method': 'PATCH',
            },
        })

        return {
            board: castBoard(data.data),
            // permissions: data.meta.permissions,
        }
    }

    async deleteBoard(boardId) {
        await this.#axios.delete('/api/v2/boards/' + boardId)
    }

    async addBoardToFavorites(boardId) {
        const {data} = await this.#axios.post(`/api/v2/boards/${boardId}/favorites`);

        return {
            board: castBoard(data.data),
        };
    }

    async removeBoardFromFavorites(boardId) {
        const {data} = await this.#axios.delete(`/api/v2/boards/${boardId}/favorites`);

        return {
            board: castBoard(data.data),
        };
    }

    async getBoardStatistics(boardId) {
        const {data} = await this.#axios.get('/api/v2/boards/' + boardId + '/statistics?load=spending,expenses,daily')

        return data
    }

    async createBoardPhantom(boardId, input) {
        const {data} = await this.#axios.post('/api/v2/boards/' + boardId + '/members/phantoms', input)

        return data
    }

    async directlyInviteFriend(boardId, input) {
        const {data} = await this.#axios.post('/api/v2/boards/' + boardId + '/members/users', input);

        return data;
    }

    async deleteBoardMember(boardId, memberId) {
        await this.#axios.delete('/api/v2/boards/' + boardId + '/members/' + memberId)
    }

    async leaveBoard(boardId) {
        await this.#axios.post('/api/v2/boards/' + boardId + '/leave');
    }

    async regenerateInviteLink(boardId) {
        const {data} = await this.#axios.post('/api/v2/boards/' + boardId + '/invite');

        return {
            board: castBoard(data.data),
        };
    }

    async getInviteInfo(token) {
        const {data} = await this.#axios.get('/api/v2/invite/' + token);

        return data;
    }

    async acceptInvite(token) {
        const {data} = await this.#axios.post('/api/v2/invite/' + token);

        return data.data;
    }

    async getBoardPayments(boardId, page, limit = 50) {
        const {data} = await this.#axios.get('/api/v2/boards/' + boardId + '/payments', {
            params: {
                page,
                limit,
            },
        });

        return {
            payments: castPaymentsArray(data.data),
            meta: data.meta,
        };
    }

    async getBoardPayment(boardId, paymentId) {
        const {data} = await this.#axios.get('/api/v2/boards/' + boardId + '/payments/' + paymentId);

        return {
            payment: castPayment(data.data),
            permissions: data.meta.permissions,
        };
    }

    async createBoardExpense(boardId, input) {
        const {data} = await this.#axios.post('/api/v2/boards/' + boardId + '/payments/expense', input)

        return castPayment(data.data);
    }

    async createBoardTransfer(boardId, input) {
        const {data} = await this.#axios.post('/api/v2/boards/' + boardId + '/payments/transfer', input)

        return castPayment(data.data)
    }

    async deleteBoardPayment(boardId, paymentId) {
        await this.#axios.delete('/api/v2/boards/' + boardId + '/payments/' + paymentId)
    }

    async getRegularExpenses(boardId) {
        const {data} = await this.#axios.get('/api/v2/boards/' + boardId + '/regularExpenses');

        return {
            regularExpenses: data.data,
        };
    }

    async getBoardDebts(boardId) {
        const {data} = await this.#axios.get('/api/v2/boards/' + boardId + '/debts');

        return {
            debts: castDebtsArray(data.data.debts),
            balances: data.data.balances,
            debtors: data.data.debtors,
            creditors: data.data.creditors,
        };
    }

    async mercyDebt(boardId, debtorId) {
        await this.#axios.post('/api/v2/boards/' + boardId + '/debts/mercy', {
            debtorId,
        });
    }

    async remindAboutDebt(boardId, debtorId) {
        await this.#axios.post('/api/v2/boards/' + boardId + '/debts/reminder', {
            debtorId,
        });
    }

    async getFriends(filters = {}) {
        const {data} = await this.#axios.get('/api/v2/friends', {
            params: {
                filter: filters,
            },
        });

        return data.data;
    }

    async getUserDebts() {
        const {data} = await this.#axios.get('/api/v2/debts');

        return data.data;
    }

    async getCurrenciesList() {
        const {data} = await this.#axios.get('/api/v2/currencies');

        return data.data;
    }
}