import {NavLink, Route, Routes, useNavigate, useParams} from 'react-router-dom';
import {useAppContext} from '../../context/appContext';
import React, {useEffect, useRef, useState} from 'react';
import Avatar from '../Avatar/Avatar';
import AvatarPlaceholder from '../Avatar/AvatarPlaceholder';
import Debts from './Debts';
import Members from './Members/Members';
import Payments from './Payments/Payments';
import Modal from '../Modal/Modal';
import AvatarInput from '../Avatar/AvatarInput';
import Analytics from './Analytics';
import RegularExpenses from './RegularExpenses/RegularExpenses';
import {Tooltip} from 'bootstrap';
import {useStateBool} from '../../services/util/useStateBool';

export default function BoardPage() {
    const {boardId} = useParams()
    const navigate = useNavigate()

    const {api, auth, toast} = useAppContext()

    const allowRegularExpenses = false && auth.getUser().isPro()

    const [initialLoading, _, finishInitialLoading] = useStateBool(true)
    const [board, setBoard] = useState(null)
    const [permissions, setPermissions] = useState(null)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    useEffect(() => {
        loadBoard().then(() => {
            finishInitialLoading()
        })
    }, [])

    const loadBoard = async () => {
        try {
            const {board, permissions} = await api.getBoard(boardId)
            setBoard(board)
            setPermissions(permissions)
        } catch (e) {
            if (e.response?.status === 404 || e.response?.status === 403) {
                navigate('/404')
            } else {
                throw e
            }
        }
    }

    const handleUpdate = async (data) => {
        const {board: newBoard} = await api.updateBoard(board.id, data)
        setBoard(newBoard)
        setIsEditModalOpen(false)
    }

    const handleDelete = async () => {
        if (!window.confirm('Вы уверены, что хотите удалить доску?')) {
            return
        }

        await api.deleteBoard(board.id)
        navigate('/')
    }

    const handleArchive = async () => {
        if (!window.confirm('Вы уверены, что хотите удалить доску?')) {
            return
        }

        await api.deleteBoard(board.id)
        navigate('/')
    }

    const handleRegenerate = async () => {
        const {board} = await api.regenerateInviteLink(boardId)
        setBoard(board)
    }

    const handleLeave = async () => {
        if (!window.confirm('Вы уверены, что хотите покинуть доску?')) {
            return
        }

        await api.leaveBoard(boardId)
        navigate('/')
    }

    const handleRemoveMember = async (member) => {
        if (!window.confirm('Вы уверены, что хотите исключить этого участника?')) {
            return
        }
        try {
            await api.deleteBoardMember(board.id, member.id)
            loadBoard()
        } catch (e) {
            // alert('Ошибка удаления участника')
            toast.error('Ошибка удаления участника')
        }
    }

    const handleCreatePhantom = async (data) => {
        await api.createBoardPhantom(board.id, data)
        loadBoard()
    }

    const handleAddFriend = async (friend) => {
        await api.directlyInviteFriend(board.id, {
            userId: friend.user.id,
        })
        loadBoard()
    }

    if (initialLoading) {
        return (
            <>
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex placeholder-glow">
                        <AvatarPlaceholder size={'medium'}/>
                        <span className="h3 mx-2 my-auto fw-bold placeholder rounded-1" style={{width: '140px'}}/>
                    </div>
                    <div/>
                </div>

                <ul className="nav nav-underline mb-3 justify-content-between justify-content-sm-start placeholder-glow">
                    {[...Array(4).keys()].map(i => (
                        <li key={i} className="nav-item">
                            <span
                                className="nav-link placeholder rounded-1"
                                style={{width: '80px', height: '100%'}}
                            >&nbsp;</span>
                        </li>
                    ))}
                </ul>
            </>
        )
    }

    return (
        <>
            <EditBoardModal
                board={board}
                canDelete={permissions?.delete ?? false}
                canArchive={permissions?.archive}
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onSubmit={handleUpdate}
                onDelete={handleDelete}
                onArchive={handleArchive}
            />

            <div className="d-flex justify-content-between mb-3">
                <div className="d-flex">
                    <Avatar emoji={board.avatar.emoji} color={board.avatar.color} size={'medium'}/>
                    <span className="h3 mx-2 my-auto fw-bold">
                        {board.name}
                    </span>
                </div>
                <div>
                    {permissions.edit && (
                        <button
                            className="btn btn-link text-decoration-none"
                            onClick={() => setIsEditModalOpen(true)}
                        >
                            <i className="bi bi-gear-wide-connected"></i>
                        </button>
                    )}
                </div>
            </div>

            <ul className="nav nav-underline mb-3 justify-content-between justify-content-sm-start">
                <li className="nav-item">
                    <NavLink
                        end
                        to={'/boards/' + boardId}
                        className="nav-link text-body"
                    >
                        Платежи
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        end
                        to={'/boards/' + boardId + '/debts'}
                        className="nav-link text-body"
                    >
                        Долги
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        to={'/boards/' + boardId + '/members'}
                        className="nav-link text-body"
                    >
                        Участники
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        to={'/boards/' + boardId + '/analytics'}
                        className="nav-link text-body"
                    >
                        Аналитика
                    </NavLink>
                </li>
                {allowRegularExpenses && (
                    <li className="nav-item">
                        <NavLink
                            to={'/boards/' + boardId + '/regularExpenses'}
                            className="nav-link"
                        >
                            Регулярные платежи
                        </NavLink>
                    </li>
                )}
            </ul>

            <Routes>
                <Route
                    index
                    element={<Payments board={board}/>}
                />
                <Route
                    path="debts"
                    element={<Debts board={board}/>}
                />
                <Route
                    path="members"
                    element={<Members
                        board={board}
                        permissions={permissions}
                        onRegenerate={handleRegenerate}
                        onLeave={handleLeave}
                        onRemoveMember={handleRemoveMember}
                        onCreatePhantom={handleCreatePhantom}
                        onAddFriend={handleAddFriend}
                    />}
                />
                <Route
                    path="analytics"
                    element={<Analytics board={board}/>}
                />
                <Route
                    path="regularExpenses"
                    element={<RegularExpenses board={board}/>}
                />
            </Routes>
        </>
    )
}

function CurrencyBadge({currency}) {
    const tooltipRef = useRef()

    useEffect(() => {
        const tooltip = new Tooltip(tooltipRef.current, {
            title: currency.title + ' (' + currency.symbol + ')',
            placement: 'bottom',
            trigger: 'hover',
        })

        return () => {
            tooltip.dispose()
        }
    }, []);

    return (
        <span
            className="badge bg-success"
            ref={tooltipRef}
        >
            {currency.flagEmoji} {currency.iso3}
        </span>
    )
}

function EditBoardModal({board, canDelete, canArchive, isOpen, onClose, onSubmit, onDelete, onArchive}) {
    const emptyForm = () => ({
        name: board.name,
        emoji: board.avatar.emoji,
        color: '#' + board.avatar.color,
    })

    const [form, setForm] = useState(emptyForm())

    const handleClose = () => {
        setForm(emptyForm())
        onClose()
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        onSubmit({
            name: form.name,
            avatar: {
                emoji: form.emoji,
                color: form.color.slice(1),
            },
        })
    }

    return (
        <>
            {isOpen && (
                <Modal onClose={handleClose}>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Редактирование доски
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder="."
                                    value={form.name}
                                    onChange={e => setForm({...form, name: e.target.value})}
                                />
                                <label htmlFor="name" className="form-label">
                                    Название
                                </label>
                            </div>

                            <div className="mb-3">
                                <AvatarInput
                                    emoji={form.emoji}
                                    color={form.color}
                                    setEmoji={v => setForm({...form, emoji: v})}
                                    setColor={v => setForm({...form, color: v})}
                                />
                            </div>

                            <button
                                type="submit"
                                className="btn btn-success"
                            >
                                Сохранить
                            </button>
                        </form>

                        {canDelete && (
                            <>
                                <hr/>

                                <button
                                    className="btn btn-outline-danger"
                                    onClick={onDelete}
                                >
                                    Удалить доску
                                </button>
                            </>
                        )}
                        {canArchive && (
                            <>
                                <hr/>

                                <button
                                    className="btn btn-outline-danger"
                                    onClick={onArchive}
                                >
                                    Архивировать доску
                                </button>
                            </>
                        )}
                    </div>
                </Modal>
            )}
        </>
    )
}